$(document).ready(function(){
    MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
    var observer = new MutationObserver(function() {
        if(document.getElementsByClassName('SubsanarWrapper')[0]){
            let _subsanarwrapper = document.getElementsByClassName('SubsanarWrapper')[0].children;
            if(_subsanarwrapper.length > 0){
                var s;
                for (s = 0; s < _subsanarwrapper.length; s++){
                    _subsanarwrapper[s].setAttribute('id', 'SubsanarDivId-'+s);
                    var _formsetsubsanar = _subsanarwrapper[s].getElementsByClassName('FormsetSubsanar')[0];
                    if(_formsetsubsanar){
                        _formsetsubsanar.getElementsByTagName('select')[0].setAttribute('name', 'subsanar['+s+'][motivo]');
                        $(_formsetsubsanar.getElementsByTagName('select')[0]).attr('required', true);
                        _formsetsubsanar.getElementsByTagName('textarea')[0].setAttribute('name', 'subsanar['+s+'][observacion]');
                        $(_formsetsubsanar.getElementsByTagName('textarea')[0]).attr('required', true);
                    }
                    _subsanarwrapper[s].classList.remove('d-none');
                }
            }
            if (_subsanarwrapper.length != 0){
                let divObservacionesSubsanar = document.querySelector('#btnRespuestaSubsanar');
                divObservacionesSubsanar.disabled = false;
                divObservacionesSubsanar.classList.remove('disabled');
                divObservacionesSubsanar.classList.remove('d-none');
            } else {
                let divObservacionesSubsanar = document.querySelector('#btnRespuestaSubsanar');
                divObservacionesSubsanar.disabled = true;
                divObservacionesSubsanar.classList.add('disabled');
                divObservacionesSubsanar.classList.add('d-none');
            }
            if(_subsanarwrapper.length >= 4){
                $('.AgregarFormsetSubsanar').addClass('disabled').attr('disabled' , 'disabled');
            } else {
                $('.AgregarFormsetSubsanar').removeClass('disabled').removeAttr('disabled');
            }
        }
        if(document.getElementsByClassName('RechazarWrapper')[0]){
            let _rechazarwrapper = document.getElementsByClassName('RechazarWrapper')[0].children;
            if(_rechazarwrapper.length > 0){
                var o;
                for (o = 0; o < _rechazarwrapper.length; o++){
                    _rechazarwrapper[o].setAttribute('id', 'RechazarDivId-'+o);
                    let _formsetrechazar = _rechazarwrapper[o].getElementsByClassName('FormsetRechazar')[o];
                    _formsetrechazar.getElementsByTagName('select')[o].setAttribute('name', 'rechazar['+o+'][motivo]');
                    _formsetrechazar.getElementsByTagName('select')[o].required = true;
                    _formsetrechazar.getElementsByTagName('textarea')[o].setAttribute('name', 'rechazar['+o+'][observacion]');
                    _formsetrechazar.getElementsByTagName('textarea')[o].required = true;
                }
            }
            if (_rechazarwrapper.length != 0){
                let divObservacionesRechazar = document.querySelector('#btnRespuestaRechazar');
                divObservacionesRechazar.disabled = false;
                divObservacionesRechazar.classList.remove('disabled');
                divObservacionesRechazar.classList.remove('d-none');
            } else {
                let divObservacionesRechazar = document.querySelector('#btnRespuestaRechazar');
                divObservacionesRechazar.disabled = true;
                divObservacionesRechazar.classList.add('disabled');
                divObservacionesRechazar.classList.add('d-none');
            }
            if(_rechazarwrapper.length >= 1){
                $('.AgregarFormsetRechazar').addClass('disabled').attr('disabled' , 'disabled');
            } else {
                $('.AgregarFormsetRechazar').removeClass('disabled').removeAttr('disabled');
            }
        }
    });
    if(document.getElementById('frmRespuesta')){
        observer.observe(document.getElementById('frmRespuesta'), {
            subtree: true,
            childList: true
        });
    }

    $(".btn-estatus").on('click', function(t){
        t.preventDefault();
        let estatus = $(this).data('estatus');

        $("#estatus").val(estatus);
        if(estatus == '2' || estatus == '9') {
            $("#divObservaciones").show();
            $('.AgregarFormsetSubsanar').removeClass('d-none');
            $('.AgregarFormsetRechazar').addClass('d-none');
            let _rechazarwrapper = document.getElementsByClassName('RechazarWrapper')[0].children;
            if(_rechazarwrapper.length > 0){
                Array.from(_rechazarwrapper).forEach(el => {
                    el.remove();
                });
            }

            let count = 0;
            let $template = $('.SubsanarDiv');
            let $container = $('.SubsanarWrapper');
            $('.AgregarFormsetSubsanar').off("click");
            $('.AgregarFormsetSubsanar').on('click', function(e){
                e.preventDefault();
                count++;
                let $copy = $template.clone();
                $copy.find('select').attr('id', 'subsanar'+count);
                $container.append($copy);
                $('#subsanar'+count).select2({
                    theme: 'bootstrap4'
                }).on('change', function(e){
                    let id = $(this).val();
                    let observacionSubsanar = this.nextElementSibling.nextElementSibling;
                    if(id){
                        $.ajax({
                            method: "POST",
                            url: "motivo",
                            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
                            data: { id }
                        }).done(function( data ) {
                            if(data.success==true) {
                                $(observacionSubsanar).text(data.detalle);
                            } else {
                                console.log(data);
                            }
                        });
                    }
                });
            });
            $('.SubsanarWrapper').off('click');
            $('.SubsanarWrapper').on('click', '.deleteSubsanar', function (e) {
                $(this).parent().parent().parent().parent().parent().remove();
            });
        }
        if(estatus == '5') {
            $("#divObservaciones").show();
            $('.AgregarFormsetSubsanar').addClass('d-none');
            $('.AgregarFormsetRechazar').removeClass('d-none');
            let _subsanarwrapper = document.getElementsByClassName('SubsanarWrapper')[0].children;
            if(_subsanarwrapper.length > 0){
                Array.from(_subsanarwrapper).forEach(el => {
                    el.remove();
                });
            }
            $('.AgregarFormsetRechazar').off("click");
            $('.AgregarFormsetRechazar').on('click', function(e){
                e.preventDefault();
                var _html = $('.RechazarDiv').html();
                $('.RechazarWrapper').append(_html);
                $(_html).remove('d-none');

                var $eventRechazar = $("#rechazar");
                $eventRechazar.select2({
                    theme: 'bootstrap4'
                }).on("change", function (e) {
                    let id = $(this).val();
                    if(id){
                        $.ajax({
                            method: "POST",
                            url: "motivo",
                            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
                            data: { id }
                        }).done(function( data ) {
                            if(data.success==true) {
                                $('#observaciones').text(data.detalle);
                            } else {
                                console.log(data);
                            }
                        });
                    }
                });
            });
            $('.RechazarWrapper').off('click');
            $('.RechazarWrapper').on('click', '.deleteRechazar', function (e) {
                $(this).parent().parent().parent().parent().remove();
            });
        }
        if(estatus == '5' && estatus && '2' && estatus == '9' && estatus == '12') {
            $("#divObservaciones").hide();
        }
        if (estatus == '12') {
          event.preventDefault();
          var form = $("#frmRespuesta");
          form.validate({
              lang: 'es'
          });
          if(form.valid()){
              Swal.fire({
                  title: '¿Estás seguro?',
                  text: "¡No podrás revertir esto!",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#2fa360',
                  cancelButtonColor: '#d33',
                  cancelButtonText: 'Cancelar',
                  confirmButtonText: 'Confirmar'
              }).then((result) => {
                  if (result.value) {
                      $("#frmRespuesta").submit();
                  }
              });
          }
        }
    });

    $(".btn-confirm").click(function(event){
        event.preventDefault();
        var form = $("#frmRespuesta");
        form.validate({
            lang: 'es'
        });
        if(form.valid()){
            Swal.fire({
                title: '¿Estás seguro?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2fa360',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Confirmar'
            }).then((result) => {
                if (result.value) {
                    $("#frmRespuesta").submit();
                }
            });
        }

    });

    $("#btn-regresar").click(function(event){
        event.preventDefault();

        let id     = $(this).data('id');
        let back   = $(this).prop('href');

        if(id){
            $.ajax({
                method: "POST",
                url: "update",
                headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
                data: { id }
            })
            .done(function( data ) {

            if(data.success==true)
            {
                    $(location).attr('href', back);
            }
            else
            {
                console.log(data);
            }
            });
        }

    });

    $("#frmUpdatePoblacion").submit(function(event){
        event.preventDefault();

        let id        = $("#id_solicitud").val();
        let poblacion = $("#tipo_poblacion").val();

        if(id && poblacion){

            Swal.fire({
                title: '¿Estás seguro?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2fa360',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Confirmar'
              }).then((result) => {
                if (result.value) {
                    $.ajax({
                        method: "POST",
                        url: "updatePoblacion",
                        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
                        data: { id, poblacion }
                    })
                    .done(function( data ) {

                    if(data.success==true)
                    {
                        $("#msjUpdatePoblacion").html('<i class="fas fa-check text-success"> La actualización se realizo correctamente</i>');
                    }
                    else
                    {
                        $("#msjUpdatePoblacion").html('<i class="fas fa-times text-danger"> La actualización no se realizo correctamente</i>');
                    }
                    });
                }
              });
        }
    });

    $("#frmUpdateSemanas").submit(function(event){
        event.preventDefault();

        let id        = $("#id_solicitud").val();
        let semanas = $("#semanas").val();
        let comprobante = $("#adjuntar_comprobante").prop('href');

        if(id && semanas){

            Swal.fire({
                title: '¿Estás seguro?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2fa360',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Confirmar'
              }).then((result) => {
                if (result.value) {
                    $.ajax({
                        method: "POST",
                        url: "updateSemanas",
                        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
                        data: { id, semanas, comprobante }
                    })
                    .done(function( data ) {

                    if(data.success==true)
                    {
                        $("#msjUpdateSemanas").html('<i class="fas fa-check text-success"> La actualización se realizo correctamente</i>');
                    }
                    else
                    {
                        $("#msjUpdateSemanas").html('<i class="fas fa-times text-danger"> La actualización no se realizo correctamente</i>');
                    }
                    });
                }
              });
        }
    });

    $('.only-numbers').on('input', function () {
        this.value = this.value.replace(/[^0-9]/g,'');
    });

    $('.file').click(function(e){
        e.preventDefault();

        let input = $(this).prop('id');

        $('#inputFile').attr('data-input', input);

        $('#inputFile').click();
    });

    $('#inputFile').change(function(event){

        let input = $(this).attr('data-input');
        let file = this.files[0];

        if (file.size > 5000000) {
            alert('El archivo no debe superar los 5MB');
            return false;
        }

        $(`#${input}`).prop('disabled', true);

        $.ajax({
            method: "POST",
            url: `upload`,
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            data: new FormData($('#frmUpload')[0]),
            cache: false,
            contentType: false,
            processData: false,

            xhr: function () {
                //Progress
                $(`.${input}`).removeClass('d-none');
                var myXhr = $.ajaxSettings.xhr();

                if (myXhr.upload) {
                    myXhr.upload.addEventListener('progress', function (e) {
                    if (e.lengthComputable) {
                        $(`.${input}`).attr({
                            value: e.loaded,
                            max: e.total,
                        });
                    }
                    }, false);
                }
                return myXhr;
            }
          }) .done(function( data ) {

            $(`#${input}`).prop('disabled', false);

            $(`#check_${input}`).removeClass('fa-check fa-times');
            $(`#msg_${input}`).html('');

            $("#inputFile").val('');

            if(data.success==true)
            {
                $(`#check_${input}`).addClass('fa-check text-success');
                $(`#${input}`).removeClass('d-none');
                $(`#${input}`).prop('href', data.file);
                $(`#view_${input}`).html(`<a href="${data.file}" class="text-decoration-none" target="_BLANK"><i class="fa fa-file fa-lg"></i> Ver Comprobante</a>`);
            }
            else
            {
                $(`#check_${input}`).addClass('fa-times text-danger');
                $(`#msg_${input}`).html(data.message);
                console.log(data);
            }

            $(`#check_${input}`).removeClass('d-none');
        });
    });
});
